import React from "react";
import { useAuth } from "../auth/AuthProvider";
import { useNavigate } from "react-router-dom";
import ProductForm from "./ProductForm";

const AddProductForm = () => {
  const { uploadProduct, fetchProducts } = useAuth();
  const navigate = useNavigate();

  return (
    <ProductForm
      onSave={async (theProduct, theFile) => {
        try {
          console.log("Uploading product to API:", theProduct);
          // Pass both theProduct and theFile:
          await uploadProduct(theProduct, theFile);
          console.log("Product uploaded successfully.");
          await fetchProducts();
          alert("Product added successfully!");
          navigate("/view-products");
        } catch (error) {
          console.error("Failed to add product:", error);
          alert("Failed to add product. Please try again.");
        }
      }}
    />
  );
};

export default AddProductForm;
